<template>
	<el-form ref="fixRateForm" :model="detailFrom" :rules="rules">
		<div v-for="(item, index) in detailFrom.detail" :key="index" class="a-felx-cfsfs a-mt-10 projectadd-box">
			<div class="a-flex-rfsfs">
				<le-company-underandparent-select 
                    :label="'收益方' + (index + 1)" 
                    v-model="item.targetCompanyId" large>
                </le-company-underandparent-select>
				<i class="el-icon-remove-outline a-c-red a-fs-16 a-ml-15 a-mt-16" v-if="index > 0" @click="handlerDelFixedIncome(index)"></i>
			</div>
            <div class="a-flex-rfsfs" v-if="company.isSystem && (userInfo.dataPermission == 99)">
				<!-- <le-company-underandparent-select 
                    label="上级商户" 
                    v-model="item.showCompanyId" large>
                </le-company-underandparent-select> -->
                <le-select-local-search 
                    label="上级商户" 
                    large
                    v-model="item.showCompanyId" 
                    :options="showCompanyOptions" />
			</div>
			<el-form-item :prop="'detail.' + index + '.rate'" :rules="{ validator: validateDetail, trigger: 'change' }">
				<div class="a-flex-rfsc">
					<span style="width: 150px; margin-top: 4px">分成比例</span>
					<el-input placeholder="请输入分成比例" type="number" min="0" v-model="item.rate" large
						style="width: 376px !important" :max="maxRate">
						<template slot="append">%</template>
					</el-input>
				</div>
			</el-form-item>
		</div>
		<div class="a-flex-rfsc a-mt-10 addFixedIncom" @click="handlerAddFixedIncome">
			<i class="el-icon-circle-plus-outline a-c-blue a-fs-16"></i>
			<span class="a-ml-03 a-c-blue">添加</span>
		</div>
	</el-form>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		props: {
			value:{
				type:Array
			},
			incomeId: {
				type: Number,
			},
			maxRate: {
				type: Number
			},
			ownerCompanyId: {
				type: Number
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company,
                userInfo: state => state.user.user,
			})
		},
		data() {
			return {
				detailFrom:{
					detail: [],
				},
				ownerRate:0,//本商户收益
				rules: {},
                fZTemplateOptions: {
                    url: this.$Config.apiUrl.getIncomePlanList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "planName",
                    valueKey: "id",
                    searchKey: "planName",
                },
                showCompanyOptions: [{
                    label: '上海玖电科技有限公司',
                    value: 2
                }]
			}
		},
		watch: {
			value:{
				handler(val) {
					if (val) {
						this.detailFrom.detail = val
					}
				},
				deep:true,
				immediate:true
			},
			incomeId: {
				handler(val) {
					if (val) {
						this.getIncomeDetail();
					}
				}
			},
			maxRate: {
				handler(val) {
					this.setDefaultRate()
				}
			},
			ownerCompanyId:{
				handler(val) {
					this.setDefaultRate()
				}
			},
			'detailFrom.detail':{
				handler(val) {
					this.$emit('input',val)
				},
				deep:true,
			}
		},
		methods: {
			/**
			 * 设置默认收益比例
			 */
			setDefaultRate() {
				if (this.maxRate && this.ownerCompanyId && this.detailFrom.detail.length == 0) {
					this.detailFrom.detail.push({
						targetCompanyId: this.company.id,
						showCompanyId: '',
						rate: this.maxRate,
						testRunRate: null,
						serNo:0,
                        incomePlanId: '1',
						rangeDetail:[]
					})
				}
			},
			/**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				this.$Axios._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						this.detailFrom.detail = data.targetList.map(item => {
							return {
								targetCompanyId: item.targetCompanyId,
                                showCompanyId: item.showCompanyId != 2 ? '' : item.showCompanyId,
								rate: item.rate / 100,
								testRunRate: null,
								serNo:item.serNo,
                                incomePlanId: item.incomePlanId,
								rangeDetail:[]
							}
						})
					});
			},
			/**
			 * 添加固定收益条目
			 */
			handlerAddFixedIncome() {
				/**
				 * 计算最大序号条目
				 */
				let maxSerNo = 0
				this.detailFrom.detail.map(item=>{
					if(item.serNo>=maxSerNo){
						maxSerNo = item.serNo
					}
				})
				let rateDetai = {
					targetCompanyId: null,
                    showCompanyId: null,
					rate: 0,
					testRunRate: null,
					serNo:maxSerNo+1,
                    incomePlanId: "1",
					rangeDetail:[]
				};
				this.detailFrom.detail.push(rateDetai);
			},
			
			handlerDelFixedIncome(index){
				this.detailFrom.detail.splice(index,1)
			},
			
			/**
			 * form校验
			 * @param {Object} rule
			 * @param {Object} value
			 * @param {Object} callback
			 */
			validateDetail(rule, value, callback) {
				let index = rule.field.split(".")[1];
				let _value = this.detailFrom.detail[index].rate
				if(!(_value+'').match(/^\d{1,3}(\.\d{1,2})?$/)){
					return callback(new Error("请输入正确数值,最大"+this.maxRate+",最小0,小数点后最多2位"));
				}
				if(_value>this.maxRate || _value<0){
					return callback(new Error("请输入正确数值,最大"+this.maxRate+",最小0,小数点后最多2位"));
				}
				
				/**
				 * 过滤获取本商户收益
				 */
				this.ownerRate = this.detailFrom.detail.map((item, index) => {
					if(item.targetCompanyId == this.company.id){
						return item.rate ? item.rate : 0; 
					}else{
						return 0;
					}
				}).reduce(function(prev, cur) {
					return Number(prev) + Number(cur);
				});
				
				let totalRate = this.detailFrom.detail.map(item=>{
					return item.rate
				}).reduce(function(prev, cur) {
					return Number(prev||0) + Number(cur||0);
				});
				
			
			
				if (!this.ownerCompanyId) {
					return callback(new Error("请选择所属商户"));
				} else if (!this.detailFrom.detail[index].targetCompanyId) {
					return callback(new Error("请选择对应的收益方"));
				} else if (totalRate > this.maxRate) {
					return callback(new Error("设置的分成总比例"+totalRate+"%不能超过最大可分配比例"+this.maxRate+'%'));
				} else if (!this.detailFrom.detail[index].incomePlanId) {
					return callback(new Error('请选择收益方分账模板'));
				} else {
					return callback();
				}
			},
		}
	}
</script>

<style>
</style>