function formatDate(date) {
	let nDate = date || new Date()

	const year = nDate.getFullYear();
	let month = nDate.getMonth() + 1;
	let day = nDate.getDate();
	const hours = nDate.getHours();
	const minuts = nDate.getMinutes();
	const seconds = nDate.getSeconds();
	if (month < 10) {
		month = '0' + month
	}
	day = day < 10 ? '0' + day : day

	return year + '-' + month + '-' + day
}

function formatMonth(date) {
	let nDate = date || new Date()

	const year = nDate.getFullYear();
	let month = nDate.getMonth() + 1;
	let day = nDate.getDate();
	const hours = nDate.getHours();
	const minuts = nDate.getMinutes();
	const seconds = nDate.getSeconds();
	if (month < 10) {
		month = '0' + month
	}
	day = day < 10 ? '0' + day : day

	return year + '-' + month 
}


function formatTime(date) {
	let nDate = date || new Date()

	const year = nDate.getFullYear();
	let month = nDate.getMonth() + 1;
	let day = nDate.getDate();
	const hours = nDate.getHours();
	const minuts = nDate.getMinutes();
	const seconds = nDate.getSeconds();
	if (month < 10) {
		month = '0' + month
	}
	day = day < 10 ? '0' + day : day
	hours = hours < 10 ? '0' + hours : hours
	minuts = minuts < 10 ? '0' + minuts : minuts
	seconds = seconds < 10 ? '0' + seconds : seconds

	return year + '-' + month + '-' + day + ' ' + hours + ':' + minuts + ":" + seconds
}

function addYear(date,years){
	let nDate = date || new Date()
	nDate.setFullYear(nDate.getFullYear()+years)
	return nDate;
}

function addMonth(date,months){
	let nDate = date || new Date()
	nDate.setMonth(nDate.getMonth()+years)
	return nDate;
}


export default{
	formatDate,
	formatTime,
	formatMonth,
	addYear,
	addMonth
}
