<template>
	<el-form ref="rangeForm" :model="rangeFrom" :rules="rules">
		<div class="a-flex-rfsc a-ptb-12">
			<div class="projectadd-title">
				<div>单桩日均收益区间</div>
			</div>
			<div class="projectadd-box a-w-100" style="max-width: 450px;">
				<div class="a-flex-rfsc" v-for="(item, index) in rangeFrom.incomeRangeList"
					:key="index">
					<div class="a-flex-rfsc a-flex-1 " >
						<div class="a-flex-rfsc a-flex-1">
							<span>&ge;</span>
							<el-form-item :prop="'incomeRangeList.' + index + '.minAmount'" :rules="{ validator: validateRange, trigger: 'blur' }" 
								class="a-w-100"
								style="margin-bottom:0">
								<el-input placeholder="金额(元)" type="number" v-model="item.minAmount"
									:min="0"
									:max="10"
									:maxlength="5"
									class="a-ml-15" >
									<template slot="prepend">¥</template>
								</el-input>
							</el-form-item>
						</div>
						<div style="width: 42px"></div>
						<div class="a-flex-rfsc a-flex-1">
							<span>&lt;</span>
							<el-form-item :prop="'incomeRangeList.' + index + '.maxAmount'" :rules="{ validator: validateRange, trigger: 'blur' }"
								class="a-w-100"
								style="margin-bottom:0">
								<el-input placeholder="金额(元)" type="number" v-model="item.maxAmount"
									:min="0"
									:max="10"
									:maxlength="5" class="a-ml-15" >
									<template slot="prepend">¥</template>
								</el-input>
							</el-form-item>
						</div>
					</div>
					<i class="el-icon-remove-outline a-c-red a-fs-16 a-ml-15" v-if="index > 0"
						@click="handlerDelRange(index)"></i>
					<i class="a-ml-15" v-else style="width: 16px"></i>
				</div>
				<div class="a-flex-rfsc" @click="handlerAddRange" style="width: 100px;">
					<i class="el-icon-circle-plus-outline a-c-blue a-fs-16"></i>
					<span class="a-ml-03 a-c-blue">添加</span>
				</div>
			</div>
		</div>
	</el-form>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		props: {
			incomeId: {
				type: Number,
			},
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				rangeFrom:{
					incomeRangeList: [],
				},
				rules: {}
			}
		},
		watch: {
			incomeId: {
				handler(val) {
					if (val) {
						this.getIncomeDetail();
					}else{
						this.setDefaultRange();
					}
				},
				immediate:true
			},
			/**
			 * 向上传递收益区间范围
			 */
			'rangeFrom.incomeRangeList':{
				handler(val) {
					this.$emit('input',val)
				},
				deep:true,
				immediate:true
			}
		},
		methods: {
			randomId(){
				return Math.ceil(Math.random()*100000000)
			},
			/**
			 * 设置默认收益比例
			 */
			setDefaultRange() {
				if ( this.rangeFrom.incomeRangeList.length==0) {
					this.rangeFrom.incomeRangeList.push({
						_id:this.randomId(),
						type: 1,
						minAmount: 0,
						maxAmount:0.3
					})
					this.rangeFrom.incomeRangeList.push({
						_id:this.randomId(),
						type: 1,
						minAmount: 0.3,
						maxAmount:0.6
					})
					this.rangeFrom.incomeRangeList.push({
						_id:this.randomId(),
						type: 1,
						minAmount: 0.6,
						maxAmount:0.9
					})
					this.rangeFrom.incomeRangeList.push({
						_id:this.randomId(),
						type: 1,
						minAmount: 0.9,
						maxAmount:2
					})
				}
			},
			/**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				this.$Axios._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						if(data.rangeList && data.rangeList.length>0){
							this.rangeFrom.incomeRangeList = data.rangeList.map(item => {
								return {
									_id:item.id,
									type: item.type,
									minAmount: item.minAmount / 100,
									maxAmount: item.maxAmount / 100,
								}
							})
						}else{
							this.setDefaultRange()
						}
						
					});
			},
			/**
			 * 添加收益区间条目
			 */
			handlerAddRange() {
				let minAmount = 0;
				let maxAmount = 0.3;
				
				/**
				 * 获取最后一条条目的最大金额和最小金额
				 */
				if(this.rangeFrom.incomeRangeList.length>0){
					let list = this.rangeFrom.incomeRangeList
					minAmount = list[list.length-1].maxAmount;
					maxAmount = minAmount+0.5;
				}
				
				let range = {
					_id:this.randomId(),
					type: 1,
					minAmount: minAmount,
					maxAmount: maxAmount,
				};
				this.rangeFrom.incomeRangeList.push(range);
			},
			
			/**
			 * 删除项目收益区间条目
			 * @param {Object} index
			 */
			handlerDelRange(index){
				let list = this.rangeFrom.incomeRangeList
				/**
				 * 删除首条目时,第二条目继承第一条目的最小值
				 */
				if(index==0){
					if(list.length>0){
						list[index+1].minAmount = 0
					}
					list.splice(index,1)
				}else{
					if(index < list.length-1){
						/**
						 * 删除中间条目,则下条目的最小值都需要调整
						 */
						list[index+1].minAmount = list[index].minAmount
					}
					list.splice(index,1)
				}
				
			},
			
			/**
			 * form校验
			 * @param {Object} rule
			 * @param {Object} value
			 * @param {Object} callback
			 */
			validateRange(rule, value, callback) {
				let index = Number(rule.field.split(".")[1]);
				let list = this.rangeFrom.incomeRangeList;
				let isMin = rule.field.split(".")[2] == 'minAmount'
				let _value = isMin?list[index].minAmount:list[index].maxAmount

				if(!(_value+'').match(/^\d{1,2}(\.\d{1,2})?$/)){
					return callback(new Error("0-99,小数点后最多2位"));
				}
				
				if(isMin){
					if(list[index].maxAmount< _value){
						list[index].maxAmount = _value
						/**
						 * 如果后续条目的最大值小于最小值,则平推最小值到最大值
						 */
						for(let i =index+1;i<list.length;i++){
							if(list[i].maxAmount<list[i].minAmount){
								list[i].maxAmount = list[i].minAmount
								if(i<list.length-1){
									list[i+1].minAmount = list[i].maxAmount
								}
							}
						}
					}
					if(index>0){
						list[index-1].maxAmount = _value
						
						/**
						 * 如果前续条目的最大值小于最小值,则平推最大值到最小值
						 */
						for(let i =index-1;i>=0;i--){
							if(list[i].maxAmount<list[i].minAmount){
								list[i].minAmount = list[i].maxAmount
								if(i>0){
									list[i-1].maxAmount = list[i].minAmount
								}
							}
						}
					}
					
				}
				if(!isMin){
					if(list[index].minAmount> _value){
						list[index].minAmount = _value
						/**
						 * 如果前续条目的最大值小于最小值,则平推最大值到最小值
						 */
						for(let i =index-1;i>=0;i--){
							if(list[i].maxAmount<list[i].minAmount){
								list[i].minAmount = list[i].maxAmount
								if(i>0){
									list[i-1].maxAmount = list[i].minAmount
								}
							}
						}
						
					}
					if(list.length-1>index){
						list[index+1].minAmount = _value
						/**
						 * 如果后续条目的最大值小于最小值,则平推最小值到最大值
						 */
						for(let i =index+1;i<list.length;i++){
							if(list[i].maxAmount<list[i].minAmount){
								list[i].maxAmount = list[i].minAmount
								if(i<list.length-1){
									list[i+1].minAmount = list[i].maxAmount
								}
							}
						}
					}
					
				}

				
				return callback();
			},
		}
	}
</script>

<style>
</style>