<template>
    <el-dialog title="选择现勘小区" :visible.sync="dialogVisible">
        <div class="a-flex-rfsc a-w-100">
            <span>搜索小区</span>
            <le-input-icon-search placeholder="请输入小区名称" v-model="pageParam.params.key"></le-input-icon-search>
            <span class="a-ml-100">省市区</span>
            <le-input-district label="" :province.sync="pageParam.params.provinceCode"
                :city.sync="pageParam.params.cityCode" :area.sync="pageParam.params.districtCode">
            </le-input-district>
        </div>
        <div class=" a-mt-24">
            <le-pagview @setData="setTableData" :pageParam="pageParam">
                <el-table :data="areaList" height="425" size="medium">
                    <el-table-column property="areaName" label="小区名字" min-width="80"></el-table-column>
                    <el-table-column property="areaAddress" label="详细地址" min-width="200"></el-table-column>
                    <el-table-column label="现勘进度" min-width="150">
                        <template slot-scope="{ row }">
                            <el-progress :percentage="row.percent" :format="format"></el-progress>
                        </template>
                    </el-table-column>
                    <el-table-column property="stationSize" label="站点数量" min-width="80"></el-table-column>
                    <el-table-column property="statusText" label="状态" min-width="80"></el-table-column>
                    <el-table-column property="createTimeText" label="现勘时间" min-width="100"></el-table-column>
                    <el-table-column property="userName" label="现勘人员" min-width="80"></el-table-column>
                    <el-table-column label="操作" min-width="80">
                        <template slot-scope="{ row }">
                            <el-button type="info" plain @click="handlerChoosePrice(row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </div>

    </el-dialog>
</template>
<script>
import util from '../../../utils/util.js'
/**
 * 现勘选择
 */
export default {
    props: {
        visible: {
            required: true,
            type: Boolean,
            default: false,
        },
        areaId: {
            required: false,
        },
        areaName: {
            required: false,
        },
    },
    data() {
        return {
            util: util,
            dialogVisible: false,
            areaSearchKey: '',
            percentage: 0,
            pageParam: {
                url: this.$Config.apiUrl.getSurveyArea,
                method: "post",
                params: {
                    key: '',
                    provinceCode: '',
                    cityCode: '',
                    districtCode: '',
                },
            },
            areaList: []
        };
    },
    watch: {
        visible: {
            handler(val) {
                this.dialogVisible = val;
            },
        },
        dialogVisible: {
            handler(val, oldval) {
                if (val != oldval) {
                    this.$emit("update:visible", val)
                }
            }
        },
        'pageParam.params': {
            handler(val) {
                this.getAreaList();
            }
        }
    },
    mounted() {
        this.getAreaList()
    },
    methods: {
        format(percentage) {
            return percentage === 100 ? '已完成' : `${percentage}%`
        },
        handlerChoosePrice(item) {
            console.log('handlerChooseStation', item)
            this.dialogVisible = false
            this.$emit("update:areaId", item.id)
            this.$emit("update:areaName", item.areaName)
        },
        setTableData(data) {
            this.areaList = data;
        },
        getAreaList() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getSurveyArea,
                params: {
                    key: this.key,
                    provinceCode: this.pageParam.provinceCode,
                    cityCode: this.pageParam.cityCode,
                    districtCode: this.pageParam.districtCode,
                    pageNum: 1,
                    pageSize: 10
                }
            }).then((res) => {
                this.areaList = res.result.data.list

            })
        },

    },
};
</script>
