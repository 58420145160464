<template>
	<el-form ref="floatRateForm" :model="detailFrom" :rules="rules">
		<le-switch-btn label="是否开启试运行分成" :options="testRunOption" v-model="isOpenTestRun" class="testRunOption">
		</le-switch-btn>
		<div v-for="(item, index) in detailFrom.detail" :key="index" class="a-flex-rfsfs a-w-100 a-pb-10"
			style="max-width: 800px;">
            <div class="a-flex-cfsfs">
			    <div class="s-search-label a-mt-15">收益方{{ index + 1 }}</div>
			    <!-- <div class="s-search-label a-mt-15" style="margin-top: 56px">分账模板</div> -->
            </div>  
			<div class="a-flex-cfsfs a-flex-1 a-ml-20">
				<div class="a-flex-rsbc a-w-100">
					<div class="a-flex-1" style="margin-left: -20px">
						<el-form-item :prop="'detail.' + index + '.targetCompanyId'" style="width: 526px;">
							<!-- <le-company-all-select v-model="item.targetCompanyId"></le-company-all-select> -->
							<le-company-underandparent-select v-model="item.targetCompanyId"></le-company-underandparent-select>
						</el-form-item>
					</div>
					<el-form-item :prop="'detail.' + index + '.testRunRate'"
						:rules="{ validator: validateTestRun, trigger: 'change' }">
						<div class="a-flex-rfsc a-flex-1" style="margin-left: 72px" v-if="isOpenTestRun">
							<div>试运行30天分成</div>
							<el-input placeholder="请输入" type="number" min="0" v-model="item.testRunRate"
								class="a-ml-15 a-flex-1" :max="maxRate">
								<template slot="append">%</template>
							</el-input>
						</div>
					</el-form-item>
				</div>
                <!-- <div class="a-flex-rfsfs" style="margin-left: -20px">
                    <le-select-remote-search label="" style="width: 526px" v-model="item.incomePlanId" :options="fZTemplateOptions" placeholder="请选择" />
                </div> -->
				<div class="projectadd-box a-w-100 a-mt-5">
					<div v-for="(oitem, oindx) in item.rangeDetail" :key="oindx" class="a-flex-rfsc">
						<div class="a-flex-rfsc a-flex-1">
							<div class="a-flex-rfsc a-flex-1">
								<span>&ge;</span>
								<el-input placeholder="金额(元)" type="number" v-model="oitem.minAmount" class="a-ml-15"
									readonly disabled>
									<template slot="prepend">¥</template>
								</el-input>
							</div>
							<div class="a-flex-rfsc a-ml-12 a-flex-1">
								<span>&lt;</span>
								<el-input placeholder="金额(元)" type="number" v-model="oitem.maxAmount" class="a-ml-15"
									readonly disabled>
									<template slot="prepend">¥</template>
								</el-input>
							</div>
						</div>
						<el-form-item :prop="'detail.' + index + '.rangeDetail.' + oindx"
							:rules="{ validator: validateDetail, trigger: 'change' }">
							<div class="a-flex-rfsc" style="margin-left: 26px">
								<span>分成比例</span>
								<el-input placeholder="请输入分成比例" type="number" :min="0" v-model="oitem.rate"
									class="a-ml-30" :max="maxRate">
									<template slot="append">%</template>
								</el-input>
							</div>
						</el-form-item>
					</div>
				</div>
			</div>
			<i class="el-icon-remove-outline a-c-red a-fs-16 a-ml-15 a-mt-16" v-if="index > 0"
				@click="handlerDelIncomeDetail(index)"></i>
			<i class="a-ml-15" v-else style="width: 16px"></i>
		</div>
		<div class="a-flex-rfsc addbtn" @click="handlerAddIncomeDetail">
			<div class="s-search-label"></div>
			<i class="el-icon-circle-plus-outline a-c-blue a-fs-16 a-ml-20"></i>
			<span class="a-ml-03 a-c-blue">添加收益方</span>
		</div>
	</el-form>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		props: {
			value: {
				tyep: Array,
			},
			incomeId: {
				type: Number,
			},
			maxRate: {
				type: Number
			},
			ownerCompanyId: {
				type: Number
			},
			rangeList: {
				type: Array
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				detailFrom: {
					detail: [],
				},
				ownerRate: 0, //本商户收益
				rules: {},
				sourceDetail: [], //编辑时从接口返回的原始明细
				rateType: null,
				initFromSouce: false, //是否进行过接口内容初始化
				isOpenTestRun: false, //是否开启试运行
				testRunOption: [{
					label: '开启',
					value: true
				}, {
					label: '关闭',
					value: false
				}],
                fZTemplateOptions: {
                    url: this.$Config.apiUrl.getIncomePlanList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "planName",
                    valueKey: "id",
                    searchKey: "planName",
                },
			}
		},
		watch: {
			value: {
				handler(val) {
					if (val) {
						this.detailFrom.detail = val
						this.setRateDetail();
					}
				},
				immediate: true,
				deep: true
			},
			incomeId: {
				handler(val) {
					if (val) {
						this.getIncomeDetail();
					}
				},
				immediate: true
			},
			maxRate: {
				handler(val) {
					this.setDefaultRate()
				},
				immediate: true
			},
			ownerCompanyId: {
				handler(val) {
					this.setDefaultRate()
				},
				immediate: true
			},
			rangeList: {
				handler(val) {
					this.setDefaultRate()
					this.setRateDetail()
				},
				deep: true,
				immediate: true
			},
			sourceDetail: {
				handler(val) {
					this.setRateDetail()
				},
				deep: true,
				immediate: true
			},
			'detailFrom.detail': {
				handler(val) {
					this.$emit('input', val)
				},
				deep: true,
			},
			isOpenTestRun: {
				handler(val) {
					this.$emit('update:isOpenTestRun', val)
				},
				deep: true,
				immediate: true
			}
		},
		methods: {
			/**
			 * 设置默认收益比例
			 */
			setDefaultRate() {
				console.log('float-rate component setDefaultRate', this.maxRate, this.ownerCompanyId, this.detailFrom
					.detail, this.rangeList)
				if (this.maxRate && this.ownerCompanyId && this.detailFrom.detail.length == 0 && this.rangeList.length >
					0) {
					this.detailFrom.detail.push({
						targetCompanyId: this.ownerCompanyId,
                        incomePlanId: '1',
						rate: 0,
						testRunRate: 0,
						serNo: 1,
						rangeDetail: this.rangeList.map(item => {
							return {
								_id: item._id,
								rate: this.maxRate,
								minAmount: item.minAmount,
								maxAmount: item.maxAmount,
							}
						})
					})
				}
			},
			/**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				console.log('float-rage components getIncomeDetail')
				this.$Axios._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						this.rateType = data.rateType
						this.sourceDetail = data.targetList
						console.log('this.sourceDetail get success', this.sourceDetail)
					});
			},
			/**
			 * 动态调整收益明细
			 */
			setRateDetail() {
				console.log('setRateDetail', this.initFromSouce, this.rangeList, this.sourceDetail)
				if (!this.incomeId) {
					this.initFromSouce = true;
				}
				if (!this.initFromSouce && this.rangeList.length > 0 && this.sourceDetail.length > 0) {
					let _details = []
					/**
					 * 从接口返回了初始化数据
					 */
					this.sourceDetail.map(item => {
						let details = _details.filter(detail => {
							return item.targetCompanyId == detail.targetCompanyId && item.serNo == detail
								.serNo
						});

						/**
						 * 是否为新构造的明细
						 */
						let isCreate = details.length == 0
						let detail = !isCreate ? details[0] : {
							targetCompanyId: item.targetCompanyId,
							rate: item.rate / 100,
							testRunRate: 0,
							serNo: item.serNo,
							rangeDetail: [],
                            incomePlanId: item.incomePlanId
						};

						/**
						 * 如果为新构造的明细则添加到明细中
						 */
						if (isCreate) {
							_details.push(detail)
						}
						/**
						 * 原始条目为试运行条目
						 */
						if (item.testRun == 1) {
							detail.testRunRate = item.rate / 100
							this.isOpenTestRun = true
							return;
						}

						if (this.rateType == 1) {
							/**
							 * 原始数据为固定收益类型
							 */
							detail.rangeDetail = this.rangeList.map(range => {
								return {
									_id: range._id,
									rate: item.rate / 100,
									minAmount: range.minAmount,
									maxAmount: range.maxAmount,
								}
							})
						} else {
							/**
							 * 原始数据为浮动收益类型,则先找到匹配的收益区间,然后获取收益区间的ID
							 */
							let range = this.rangeList.filter(range => {
								return range._id == item.rateRangeId
							})[0]
							// /**
							//  * 判断当前明细条目是否已包含了当前区间数据
							//  * 如果包含,就说明该商户是存在多条明细条目的,需要特殊遍历处理
							//  */
							// let rangeDetailItem = detail.rangeDetail.filter(item=>{
							// 	return item._id == range._id
							// })
							// if(rangeDetailItem.length == 0){
							detail.rangeDetail.push({
								_id: range._id,
								rate: item.rate / 100,
								minAmount: range.minAmount,
								maxAmount: range.maxAmount,
							})
							// }else{

							// }


						}
					})
					/**
					 * 设置已经初始化过原始数据
					 */
					this.initFromSouce = true
					this.detailFrom.detail = _details;
				} else if (this.initFromSouce && this.rangeList.length > 0) {
					/**
					 * 已初始化明细后之后的动态调整
					 * 主要考虑收益区间条目的变化和增减
					 */
					this.detailFrom.detail.map(item => {
						for (let i = 0; i < item.rangeDetail.length; i++) {
							let rangeDetailItem = item.rangeDetail[i]
							/**
							 * 收益区间条目是否还存在
							 */
							let hasRangeItem = false;
							for (let j = 0; j < this.rangeList.length; j++) {
								let rangeItem = this.rangeList[j]
								if (rangeItem._id == rangeDetailItem._id) {
									/**
									 * 条目存在,重新设置最大最小值
									 */
									rangeDetailItem.minAmount = rangeItem.minAmount;
									rangeDetailItem.maxAmount = rangeItem.maxAmount;
									hasRangeItem = true
								}
							}
							/**
							 * 区间条目不存在,则删除对应的收益明细
							 */
							if (!hasRangeItem) {
								item.rangeDetail.splice(i, 1)
							}
						}

						for (let i = 0; i < this.rangeList.length; i++) {
							let hasRangeItem = false
							for (let j = 0; j < item.rangeDetail.length; j++) {
								if (this.rangeList[i]._id == item.rangeDetail[j]._id) {
									hasRangeItem = true;
									break;
								}
							}
							if (!hasRangeItem) {
								item.rangeDetail.push({
									_id: this.rangeList[i]._id,
									rate: item.rate,
									minAmount: this.rangeList[i].minAmount,
									maxAmount: this.rangeList[i].maxAmount,
								})
							}
						}
					})

				}
			},
			/**
			 * 添加收益商户条目
			 */
			handlerAddIncomeDetail() {
				/**
				 * 计算最大序号条目
				 */
				let maxSerNo = 0
				this.detailFrom.detail.map(item => {
					if (item.serNo >= maxSerNo) {
						maxSerNo = item.serNo
					}
				})
				let rateDetai = {
					targetCompanyId: null,
					rate: 0,
					testRunRate: 0,
					serNo: maxSerNo + 1,
                    incomePlanId: '1',
					rangeDetail: this.rangeList.map(item => {
						return {
							_id: item._id,
							rate: 0,
							minAmount: item.minAmount,
							maxAmount: item.maxAmount,
						}
					})
				};
				this.detailFrom.detail.push(rateDetai);
			},

			/**
			 * 删除收益商户条目
			 */
			handlerDelIncomeDetail(index) {
				this.detailFrom.detail.splice(index, 1)
			},

			/**
			 * form校验试运行比例
			 * @param {Object} rule
			 * @param {Object} value
			 * @param {Object} callback
			 */
			validateTestRun(rule, value, callback) {
				let detailIndex = rule.field.split(".")[1];
				let _value = this.detailFrom.detail[detailIndex].testRunRate

				if (!this.isOpenTestRun) {
					return callback();
				}

				if (!(_value + '').match(/^\d{1,3}(\.\d{1,2})?$/)) {
					return callback(new Error("请输入正确数值,最大" + this.maxRate + ",最小0,小数点后最多2位"));
				}
				if (_value > this.maxRate || _value < 0) {
					return callback(new Error("请输入正确数值,最大" + this.maxRate + ",最小0,小数点后最多2位"));
				}

				let totalRate = this.detailFrom.detail.map(item => {
					return item.testRunRate
				}).reduce(function(prev, cur) {
					return Number(prev || 0) + Number(cur || 0);
				});


				if (!this.ownerCompanyId) {
					return callback(new Error("请选择所属商户"));
				} else if (!this.detailFrom.detail[detailIndex].targetCompanyId) {
					return callback(new Error("请选择对应的收益方"));
				} else if (!this.detailFrom.detail[detailIndex].incomePlanId) {
					return callback(new Error("请选择收益方的入账模板"));
				} else if (totalRate > this.maxRate) {
					return callback(new Error("设置的分成总比例" + totalRate + "%不能超过最大可分配比例" + this.maxRate + '%'));
				} else {
					return callback();
				}
			},

			/**
			 * form校验
			 * @param {Object} rule
			 * @param {Object} value
			 * @param {Object} callback
			 */
			validateDetail(rule, value, callback) {
				let detailIndex = rule.field.split(".")[1];
				let detailRangeIndex = rule.field.split(".")[3];
				let _value = this.detailFrom.detail[detailIndex].rangeDetail[detailRangeIndex].rate

				if (!(_value + '').match(/^\d{1,3}(\.\d{1,2})?$/)) {
					return callback(new Error("请输入正确数值,最大" + this.maxRate + ",最小0,小数点后最多2位"));
				}
				if (_value > this.maxRate || _value < 0) {
					return callback(new Error("请输入正确数值,最大" + this.maxRate + ",最小0,小数点后最多2位"));
				}

				/**
				 * 校验每个收益区间的总商户收益是否比maxRate小
				 */
				let rangeId = this.detailFrom.detail[detailIndex].rangeDetail[detailRangeIndex]._id

				/**
				 * 过滤获取本商户收益
				 */
				let totalRate = this.detailFrom.detail.map((item) => {
					return item.rangeDetail.map(range => {
						if (range._id == rangeId) {
							return range.rate || 0
						} else {
							return 0
						}
					}).reduce((prev, cur) => {
						return Number(prev) + Number(cur);
					})
				}).reduce(function(prev, cur) {
					return Number(prev) + Number(cur);
				});





				if (!this.ownerCompanyId) {
					return callback(new Error("请选择所属商户"));
				} else if (!this.detailFrom.detail[detailIndex].targetCompanyId) {
					return callback(new Error("请选择对应的收益方"));
				} else if (totalRate > this.maxRate) {
					return callback(new Error("设置的分成总比例" + totalRate + "%不能超过最大可分配比例" + this.maxRate + '%'));
				} else {
					return callback();
				}
			},
		}
	}
</script>

<style>
</style>
