<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main a-fs-14 projectadd">
			<el-form ref="projectForm" :model="projectForm" :rules="rules" label-position='left' style="max-width: 1200px;">
				<div class="a-fs-16 a-fw-700">基本信息</div>
				<div class="a-w-100">
					<el-row :gutter="72">
						<el-col :span="12" :offset="12" style="padding-left: 148px">
							<span class="a-c-normal">最大可分配收益</span>
							<span class="a-c-master a-ml-12">{{ companyMaxRate }}%</span>
						</el-col>
					</el-row>
					<el-row :gutter="72">
						<el-col :span="12">
							<el-form-item prop="name">
								<le-input label="项目名称" v-model="projectForm.name" placeholder="请填写" :maxlength="50">
								</le-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="companyId">
								<!-- <le-company-all-select label="所属商户" v-model="projectForm.companyId"></le-company-all-select> -->
                                <le-company-underandparent-select label="所属商户" v-model="projectForm.companyId"></le-company-underandparent-select>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row :gutter="72">
						<el-col :span="12">
							<le-switch-btn label="是否收取服务费" v-model="isHasSupportAmount"
								:options="hasSupportAmountOption">
							</le-switch-btn>
						</el-col>
						<el-col :span="12" v-if="isHasSupportAmount">
							<el-row>
								<el-col :span="18" class="a-flex-rfsc a-mt-12">
									<span style="display: inline-block; width: 126px">平台服务费</span>
									<el-input placeholder="金额(元)" v-model="projectForm.serviceFee"
										@input="projectForm.serviceFee = projectForm.serviceFee.replace(/^\.+|[^\d]+/g, '')"
										:span="10" :maxlength="3">
										<template slot="prepend">¥</template>
									</el-input>

								</el-col>
								<el-col :span="5" :offset="1">
									<le-select-local-search label="每" v-model="projectForm.serviceFeeTime"
										:options="optionTimes" class="selectTimes" />
								</el-col>
							</el-row>
						</el-col>
					</el-row>

					<el-row :gutter="72">
						<el-col :span="12">
							<le-switch-btn label="是否需要三方确认" v-model="projectForm.needConfirm"
								:options="needConfirmOption">
							</le-switch-btn>
						</el-col>
						<el-col :span="12">
							<le-switch-btn label="入账类型" v-model="projectForm.incomeType"
								:options="rzTypeDic">
							</le-switch-btn>
						</el-col>
					</el-row>

					<el-row :gutter="72">
						<el-col :span="12">
							<le-input-slot label="计费模板">
								<template v-if="!projectForm.defaultPlanId">
									<el-button class="s-btn-add-blue-dot" icon="el-icon-plus"
										@click="priceVisiable = true">选择
									</el-button>
								</template>
								<template v-else>
									<div class="a-flex-rfsfs">
										<el-input readonly v-model="defaultPlanName"></el-input>
										<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right"
											@click="priceVisiable = true">重选
										</el-button>
									</div>
								</template>
							</le-input-slot>
						</el-col>
					</el-row>
				</div>
				<div class="a-fs-16 a-fw-700 a-mt-36">电费信息</div>
				<el-row :gutter="72">
					<el-col :span="12">
						<el-form-item prop="electricCompanyId">
							<!-- <le-company-under-select label="电费承担方" v-model="projectForm.electricCompanyId"></le-company-under-select> -->
                            <le-company-underandparent-select label="所属商户" v-model="projectForm.electricCompanyId"></le-company-underandparent-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" class="a-flex-rfsc a-mt-12">
						<!-- <le-input label="电费单价" v-model="projectForm.input2" placeholder="例: 1元/1kwh" /> -->
						<div style="width: 154px">
							<span style="display: inline-block; ">电费单价</span>
						</div>

						<el-input placeholder="金额(元)" v-model="projectForm.electricUnitPrice" type="number"
							@input="projectForm.electricUnitPrice = projectForm.electricUnitPrice.replace(/^.*(\d{1})(\.\d{0,2}).*/g,'$1$2')"
							:span="10" :maxlength="10" class="a-flex-1">
							<template slot="prepend">¥</template>
							<template slot="append">/kwh</template>
						</el-input>
					</el-col>
				</el-row>
				<div class="a-fs-16 a-fw-700 a-mt-36">分成比例</div>
				<div>
					<div class="a-flex-rfsc">
						<le-switch-btn label="分成模式" v-model="projectForm.rateType" :options="sharingModeOptions">
						</le-switch-btn>
						<!-- <div class="a-flex-rfsc a-ml-100" v-if="projectForm.rateType == 1">
							<span>所属商户收益(%)</span><span class="a-ml-25">{{ incomeRange }}</span>
						</div> -->
					</div>

					<template v-if="projectForm.rateType == 2">
						<project-add-rate-float-range ref="rangeComp" :incomeId="incomeId"
							v-model="projectForm.incomeRangeList">
						</project-add-rate-float-range>
						<project-add-rate-float-rate ref="floatRateComp" :incomeId="incomeId" :maxRate="companyMaxRate"
							:ownerCompanyId="projectForm.companyId" :rangeList="projectForm.incomeRangeList"
							v-model="projectForm.detail" :isOpenTestRun.sync="isOpenTestRun">
						</project-add-rate-float-rate>
					</template>
					<template v-if="projectForm.rateType == 1">
						<project-add-rate-fixed ref="fixedRateComp" :incomeId="incomeId" :maxRate="companyMaxRate"
							:ownerCompanyId="projectForm.companyId" v-model="projectForm.detail">
						</project-add-rate-fixed>
					</template>
				</div>
				<div class="a-fs-16 a-fw-700 a-mt-36">项目合同</div>
				<div>
					<el-row :gutter="72" class="a-flex-rfsc">
						<el-col :span="12">
							<le-input-slot label="合同关联现勘">
								<template v-if="!projectForm.surveyAreaId">
									<el-button class="s-btn-add-blue-dot" icon="el-icon-plus"
										@click="surveyAreaVisiable = true">选择
									</el-button>
								</template>
								<template v-else>
									<div class="a-flex-rfsfs">
										<el-input readonly v-model="surveyAreaName"></el-input>
										<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right"
											@click="surveyAreaVisiable = true">重选
										</el-button>
									</div>
								</template>
							</le-input-slot>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="startTime" label="生效日期" class="a-flex-rfsc a-w-100 startTime">
								<el-date-picker 
                                    v-model="projectForm.startTime" 
                                    type="date" 
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    placeholder="请选择日期" :editable="false"
									:picker-options="pickerOptionStart" 
                                    class="startTimeinput"></el-date-picker>
							</el-form-item>
						</el-col>

					</el-row>

					<el-row :gutter="72" class="a-flex-rfsc">
						<el-col :span="12">
							<le-switch label="是否上传项目合同" v-model="isUploadContractFile" :acValue="true"
								:inacValue="false"></le-switch>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="endTime" label="结束日期" class="a-flex-rfsc a-w-100 startTime">
								<el-date-picker v-model="projectForm.endTime" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="请选择日期" :editable="false"
									:picker-options="pickerOptionEnd" class="startTimeinput"></el-date-picker>
								<!-- <le-input-date label="结束日期" v-model="projectForm.endTime"></le-input-date> -->
							</el-form-item>
						</el-col>

					</el-row>
					<el-row>
						<el-col :span="12" v-if="!isUploadContractFile">
							<le-input label="未上传项目合同原因" v-model="projectForm.contractLackReason" placeholder="请输入原因"
								:maxlength="50">
							</le-input>
						</el-col>
						<el-col :span="24" v-else>
							<!-- <le-upload-file label="上传项目合同" v-model="projectForm.contractFile"
								:value="projectForm.contractFile">
							</le-upload-file> -->
                            <el-form-item label="上传项目合同" class="a-flex-rfsfs a-w-100" >
                                <multi-file-choose style="margin-left: 52px" ref="multiFileChoose" :readonlyName='projectForm.name + "项目合同_"' :taxFiles.sync='contractFile' :max="1"></multi-file-choose>
							</el-form-item>
						</el-col>
					</el-row>
                    <el-row >
						<el-col  v-if="incomeId">
							<el-form-item label="备注" prop="description" label-width='150px' >
                                <el-input
                                    style="width: 400px;"
                                    type="textarea"
                                    :autosize="{ minRows: 4 }"
                                    placeholder="请输入内容"
                                    maxlength="200"
                                    show-word-limit
                                    v-model="projectForm.description">
                                </el-input>
                            </el-form-item>
						</el-col>
					</el-row>
				</div>
				<div style="height: 300px;"></div>
				<div class="a-line-t-e0 footerBox">
					<el-button type="primary" class="a-ml-24 a-mt-15" @click="onSubmit('projectForm')">立即提交</el-button>
					<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancle">
						&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
					</el-button>
				</div>
			</el-form>
		</el-card>
		<le-price-choose :visible.sync="priceVisiable" :priceId.sync="projectForm.defaultPlanId"
			:priceName.sync="defaultPlanName"></le-price-choose>
		<le-survey-area-choose :visible.sync="surveyAreaVisiable" :areaId.sync="projectForm.surveyAreaId"
			:areaName.sync="surveyAreaName"></le-survey-area-choose>
	</div>
</template>
<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	import LeSurveyAreaChoose from "../components/choose/le-survey-area-choose.vue";
	import util from "../../utils/util.js";
	import dateUtil from "../../utils/dateUtil.js"
	import ProjectAddRateFixed from './project-add-rate-fixed.vue'
	import ProjectAddRateFloatRange from './project-add-rate-float-range.vue'
	import ProjectAddRateFloatRate from './project-add-rate-float-rate.vue'
    import multiFileChoose from '../components/formcy/multi-file-choose.vue';
	export default {
		inject: ["reload"],
		components: {
			LeSurveyAreaChoose,
			'project-add-rate-fixed': ProjectAddRateFixed,
			'project-add-rate-float-range': ProjectAddRateFloatRange,
			'project-add-rate-float-rate': ProjectAddRateFloatRate,
            multiFileChoose
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				incomeId: null,


				pickerOptionStart: {
					disabledDate: (time) => { //结束日期不能小于开始日期
						if (this.projectForm.endTime) {
							return time.getTime() > this.$getDay.getTimeUnix(this.projectForm.endTime)
						}

					}
				},
				pickerOptionEnd: {
					disabledDate: (time) => { //结束日期不能小于开始日期
						if (this.projectForm.startTime) {
							return time.getTime() < this.$getDay.getTimeUnix(this.projectForm.startTime) && new Date()
								.getTime()
						} else {
							return time.getTime() < new Date().getTime() - 1 * 24 * 60 * 60 * 1000
						}

					}
				},
				projectForm: {
					id: "",
					name: "", //方案名称
					companyId: null, //项目所属商户
					electricCompanyId: null, //电费承担方
					startTime: "", //项目开始日期
					endTime: "", //项目结束日期
					incomeRangeList: [],
					detail: [],
					electricUnitPrice: 0.6, //电费单价 单位分
					contractFile: "", //合同附件
					rateType: 1, //收益划分方式：1，固定收益比例，2，浮动收益比例
					needConfirm: true, //是否需要三方确认 true
					contractLackReason: "", //合同缺少原因
					surveyAreaId: null, //现勘小区ID
					serviceFee: 0, //平台服务费  单位 分
					serviceFeeTime: null, //1月  2 季度 3年,
					defaultPlanId: "", //默认计费id
					incomeType: 1,
                    incomePlanId: '',
                    description: ''
				},
                contractFile: [],
				isOpenTestRun: false, //是否开启试运行划分
				isUploadContractFile: true, //是否上传项目合同
				isHasSupportAmount: false, //是否收取平台服务费
				hasSupportAmountOption: [{
					label: '收取',
					value: true
				}, {
					label: '不收取',
					value: false
				}],
				needConfirmOption: [],
				rzTypeDic: [],

				defaultPlanName: "", //计费名称
				surveyAreaName: "", //合同关联现勘名称
				priceVisiable: false, //站点定价选择弹窗是否显示
				surveyAreaVisiable: false, //现勘选择弹窗是否显示
				optionTimes: [{
						value: "1",
						label: "月",
					},
					{
						value: "2",
						label: "季度",
					},
					{
						value: "3",
						label: "年",
					},
				],

				sharingModeOptions: [{
						value: 1,
						label: "固定比例",
					},
					{
						value: 2,
						label: "根据收益浮动",
					},
				],
                
				companyMaxRate: 100,
				incomeRange: 0,
				rules: {
					name: [{
						required: true,
						message: "请输入项目名称",
						trigger: "blur"
					}],
					companyId: [{
						required: true,
						message: "请选择项目所属商户",
						trigger: "blur"
					}, ],
					electricCompanyId: [{
						required: true,
						message: "请选择电费承担方",
						trigger: "blur"
					}, ],
					startTime: [{
						required: true,
						message: "请选择开始时间",
						trigger: "change"
					}, ],
					endTime: [{
						required: true,
						message: "请选择结束时间",
						trigger: "change"
					}, ],
				},
			};
		},
		watch: {
			"$route.query.incomeId": {
				handler(val) {
					if (val) {
						// debugger;
						this.editIncome(this.$route.query.incomeId);
					} else {
						this.reload();
					}
				},
			},

			"projectForm.companyId": {
				handler(val) {
					if (val) {
						this.getMaxRate(val);
					} else {
						this.companyMaxRate = 0
					}

				},
			},
            company: {
                deep:true,
                handler (val) {
                    if(this.company.isSystem) {
                        this.needConfirmOption = [{
                            label: '需要',
                            value: true
                        }, {
                            label: '不需要',
                            value: false
                        }]
                    }else {
                        this.needConfirmOption = [{
                            label: '需要',
                            value: true
                        }]
                    }
                }
            }

		},
		created () {
			this.$getDic('incomeType','select').then(res=>{ this.rzTypeDic = res; })
			this.$getDic('incomePlanType','select').then(res=>{ this.fZTemplateDic = res; })
            this.getCompanyInfo()
		},
		mounted() {
			if (this.$route.query.incomeId) {
				this.incomeId = Number(this.$route.query.incomeId)
				this.getIncomeDetail();
			} else {
				this.projectForm.companyId = Number(this.company.id)
				this.projectForm.electricCompanyId = this.company.id
				this.projectForm.startTime = dateUtil.formatDate(new Date())
				this.projectForm.endTime = dateUtil.formatDate(dateUtil.addYear(new Date(), 5))
			}
		},
		methods: {
            ...mapActions({
                getCompanyInfo: 'company/getCompanyInfo'
            }),
			/**
			 * 获取项目详情
			 */
			getIncomeDetail(id) {
				let that = this;
				this.$Axios
					._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						Object.assign(this.projectForm, {
							id: data.id,
							name: data.name, //方案名称
							companyId: data.companyId, //项目所属商户
							electricCompanyId: data.electricCompanyId, //电费承担方
							startTime: data.startTimeText, //项目开始日期
							endTime: data.endTimeText, //项目结束日期
							electricUnitPrice: data.electricUnitPrice / 100, //电费单价 单位分
							contractFile: data.contractFile, //合同附件
							rateType: data.rateType, //收益划分方式：1，固定收益比例，2，浮动收益比例
							contractLackReason: data.contractLackReason, //合同缺少原因
							surveyAreaId: data.surveyAreaId, //现勘小区ID
							serviceFee: data.serviceFee / 100, //平台服务费  单位 分
							serviceFeeTime: data.serviceFeeTime, //1月  2 季度 3年,
							defaultPlanId: data.defaultPlanId, //默认计费id
							incomeType: data.incomeType,//入账类型
                            incomePlanId: data.incomePlanId,//入账模板
                            needConfirm: data.needConfirm?true:false,
						})
                        if(data.contractFile) {
                            this.$refs['multiFileChoose'].fileList = [{
                                name: data.contractFile,
                                url: data.contractFile
                            }]
                        }
                        
						if (data.serviceFee) {
							this.isHasSupportAmount = true
						}
					});
			},


			getMaxRate(id) {
				this.$Axios
					._get({
						url: this.$Config.apiUrl.getCompanyMaxRate,
						params: {
							companyId: id,
						},
					})
					.then((res) => {
						this.$set(this, "companyMaxRate", res.result.data / 100);
					});
			},
			doSubmit() {
				/**
				 * 组装请求参数,主要是考虑分与元的转换
				 */
				let data = {
					...this.projectForm,
					hasTestRun: this.isOpenTestRun,
					electricUnitPrice: this.projectForm.electricUnitPrice * 100,
					serviceFee: this.projectForm.serviceFee * 100,
                    contractFile: this.contractFile.length?this.contractFile[0].url:'',
					detail: this.projectForm.detail.map(item => {
						return {
							...item,
                            showCompanyId: item.showCompanyId?item.showCompanyId:item.targetCompanyId,
							rate: item.rate * 100,
							testRunRate: item.rate * 100,
							serNo: item.serNo,
							rangeDetail: item.rangeDetail.map(rangeItem => {
								return {
									rate: rangeItem.rate * 100,
									minAmount: rangeItem.minAmount * 100,
									maxAmount: rangeItem.maxAmount * 100,
								}
							})
						}
					}),
					incomeRangeList: this.projectForm.incomeRangeList.map(item => {
						return {
							type: item.type,
							minAmount: item.minAmount * 100,
							maxAmount: item.maxAmount * 100
						}
					})
				}


				//判断是否是提交还是修改
				let apiurl, title;
				if (this.projectForm.id) {
					apiurl = this.$Config.apiUrl.changeIncome;
					// delete that.projectForm.id;
					title = "修改项目成功";
				} else {
					apiurl = this.$Config.apiUrl.createIncome;
					title = "添加项目成功";
				}


				this.$Axios
					._post({
						url: apiurl,
						params: data,
					})
					.then((res) => {
						this.$notify({
							title: "成功",
							message: title,
							type: "success",
						});

						this.$store.dispatch("remove_current_view", this)
					})
					.catch((error) => {
						this.$notify({
							title: "失败",
							message: error.result.message,
							type: "error",
						});
					});
			},
			onSubmit(formName) {
				var that = this;
				this.$refs.projectForm.validate((valid) => {
					if (valid) {
						if (that.projectForm.rateType == 1) {
							that.$refs.fixedRateComp.$refs.fixRateForm.validate((fixedRateValid) => {
								if (fixedRateValid) {
									that.doSubmit();
								}
							});
						} else {
							that.$refs.rangeComp.$refs.rangeForm.validate((rangeValid) => {
								if (rangeValid) {
									that.$refs.floatRateComp.$refs.floatRateForm.validate((
										floatRateValid) => {
										if (floatRateValid) {
											that.doSubmit();
										}
									});
								}
							});

						}
					}
				});



			},
			//取消按钮
			handlerCancle() {
				let str;

				if (this.projectForm.id != "") {
					str = '确定取消修改？'
				} else {
					str = '确定取消新增？'
				}

				this.$confirm(str, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.dispatch("remove_current_view", this)
				}).catch(() => {

				})
			},

		},
	};
</script>
<style lang="scss">
	.projectadd {
		padding-bottom: 100px;

		&-title {
			width: 154px;
			padding-right: 20px;
			box-sizing: border-box;

			&-item {
				width: 154px;
				padding-top: 16px;
			}

			>div {
				width: 92px;
			}
		}

		&-box {
			background: #fafbfc;
			border-radius: 4px;
			border: 1px solid #dadde0;
			padding: 0 16px;
			padding-bottom: 16px;

			// padding-bottom: 19px;
			>div {
				margin-top: 16px;
			}
		}

		/deep/ .my-btn {
			&:nth-of-type(1) {
				margin-left: -4px;
			}
		}

		.addbtn {
			margin-left: 154px;
			margin-top: 16px;
		}

		.addFixedIncom {
			margin-left: 154px;
		}

		/deep/ .date-range {
			width: 100%;
		}

		.footerBox {
			left: 255px;
			right: 43px;
			position: fixed;
			bottom: 0;
			background: #fff;
			padding-bottom: 24px;
		}

		/deep/ .el-form-item__error {
			left: 112px;
		}
	}

	.projectadd {
		/deep/ .el-card__body {
			.el-form {
				padding-bottom: 100px;
			}
		}
	}

	.startTime {
		margin-bottom: 0;


		/deep/ .el-form-item__content {
			flex: 1;
		}

		.startTimeinput {
			width: 100%;
		}
	}
</style>
<style lang="scss" scoped>
	/deep/ .s-search-label {
		width: 130px !important;
		justify-content: flex-start;
	}

	.selectTimes /deep/ .s-search-label {
		width: 8px !important;
	}

	/deep/ .el-main {
		padding: 4px 36px 4px 12px;
	}

	/deep/ .s-nomarl-input .el-input__inner {
		width: 100%;
	}

	/deep/ .el-icon-my-renminbi1688 {
		font-size: 14px;
		color: #303133;
	}

	/deep/ .el-icon-my-baifenhao {
		font-size: 14px;
		color: #303133;
	}
</style>
